.introArea {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, #f5f1c4, #cfa76f, #a0633b);
  user-select: none;

  .listWrap {
    // animation: spin 50s linear infinite; /* 빙글빙글 애니메이션 추가 */
    animation: spin 4s linear infinite; /* 빙글빙글 애니메이션 추가 */

    .listItem {
      position: absolute;
      width: 48px;
      height: 48px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    //   animation: spin 5s linear infinite; /* 빙글빙글 애니메이션 추가 */
      animation: spin 2s linear infinite;
      transition: all .5s ease-in-out;
    }
  }

  .titleArea {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .btn {
      font-family:ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 2em;
      text-align: center;
      font-weight: 700;
      color: #ffffff;
      transition: transform 0.4s ease-in-out;
      cursor: pointer;
    }
  }

  .patchLink {
    position: fixed;
    bottom: 3.5em;
      font-size: 1.5em;
      color: #ffffff;
    }

    .version {
      position: fixed;
    bottom: 2em;
      font-size: 1em;
    color: #ffffff;
  }
}

.rank-btn {
  position: fixed;
//   font-family: Pretendard, sans-serif;
font-family:ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1.5em;
    color: #ffffff;
  bottom: 3.5em;
    transition: transform 0.4s ease-in-out;
    cursor: pointer;
}

@keyframes spin {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
